import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./themes/theme-default";
import Landing from "./components/landing/Landing";
import AppHeaderBar from "./components/AppHeaderBar";
import ProductPage from "./sharedComponents/product/ProductPage";
import CategoryPage from "./sharedComponents/category/CategoryPage";
import BrandsPage from "./sharedComponents/brand/BrandsPage";
import BrandPage from "./sharedComponents/brand/BrandPage";

function App() {
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppHeaderBar onChangeHeight={setHeaderHeight} />
        <div style={{ marginTop: headerHeight }}>
          <Switch>
            <Route path="/product/:id">
              <ProductPage />
            </Route>
            <Route path="/category/:id">
              <CategoryPage />
            </Route>
            <Route path="/brands">
              <BrandsPage />
            </Route>
            <Route path="/brand/:id/:tabName?">
              <BrandPage />
            </Route>
            <Route>
              <Landing />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
