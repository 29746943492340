import dotenv from "dotenv";
dotenv.config();

const urlParams = new URLSearchParams(window.location.search);

export const IS_DEV = (() => {
  const override = urlParams.get("dev_override");
  if (override !== null) {
    if (override === "0" || override === "false") {
      return false;
    }
    return true;
  }
  return process.env.NODE_ENV === "development";
})();

export const HEAP_EXTENSION_APP_ID =
  process.env.REACT_APP_HEAP_EXTENSION_APP_ID;
