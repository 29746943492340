import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { getProduct } from "../../graphql/queries";
import ProductCard from "./ProductCard";

export default function ProductPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    async function fetchProduct() {
      try {
        const { data } = await API.graphql({
          query: getProduct,
          variables: { id },
        });
        setProduct(data.getProduct);
      } catch ({ data }) {
        setProduct(data.getProduct);
      }
    }
    fetchProduct();
  }, [id]);

  if (!product) {
    return null;
  }

  return (
    <div style={styles.productPage}>
      <ProductCard product={product} />
    </div>
  );
}

const styles = {
  productPage: {},
};
