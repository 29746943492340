import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { API } from "aws-amplify";
import { ListCategorysQuery } from "../../API";
import { listCategorys } from "../../graphql/queries";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function CategoryRow() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const data: ListCategorysQuery = (
          await API.graphql({
            query: listCategorys,
          })
        ).data;
        setCategories(data.listCategorys.items);
      } catch (error) {
        console.log(error);
      }
    }
    fetchCategories();
  }, []);

  return (
    <Grid container justify="center" alignItems="center" style={styles.root}>
      {categories.map(({ id, name }) => (
        <Link
          key={id}
          component={RouterLink}
          to={`/category/${id}`}
          style={styles.category}
        >
          <Typography variant="body1">{name}</Typography>
        </Link>
      ))}
    </Grid>
  );
}

const styles = {
  root: {},
  category: {
    margin: "5px 20px",
  },
};
