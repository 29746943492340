import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { listBrandValues } from "../../graphql/queries";
import Grid from "@material-ui/core/Grid";
import Value from "../value/Value";

export default function BrandValues({ ids }) {
  const [brandValues, setBrandValues] = useState([]);
  useEffect(() => {
    let isMounted = true;
    async function fetchBrandValues() {
      try {
        const { data } = await API.graphql({ query: listBrandValues });
        if (isMounted) {
          setBrandValues(data.listBrandValues.items);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchBrandValues();
    return () => {
      isMounted = false;
    };
  }, [ids]);

  if (brandValues.length === 0) {
    return null;
  }

  const values = [];
  ids.forEach((id) => {
    const value = brandValues.find((value) => value.id === id);
    if (value) {
      values.push(value);
    }
  });

  return (
    <Grid container>
      {values.map(({ value }) => (
        <Grid key={value.id} item>
          <Value id={value.name} />
        </Grid>
      ))}
    </Grid>
  );
}
