import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import { createWaitlistItem } from "../../graphql/mutations";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import stem from "../../images/stem.jpg";

export default function Signup() {
  const [joined, setJoined] = useState<boolean>(false);
  const [joinClicked, setJoinClicked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  async function handleSubmit() {
    if (!validateEmail(email)) {
      setError(true);
      return;
    }

    try {
      await API.graphql({
        query: createWaitlistItem,
        variables: {
          input: {
            email,
          },
        },
      });
      setEmail("");
      setJoinClicked(false);
      setJoined(true);
    } catch (error) {
      setEmail("");
    }
  }

  function handleChangeEmail(e) {
    if (error) {
      setError(false);
    }
    setEmail(e.target.value);
  }

  return (
    <div style={styles.root}>
      <Grid container alignItems="flex-start">
        <Grid item sm={12} md={7}>
          <Grid container justify="center" alignItems="stretch">
            <Grid item xs={11} sm={9}>
              <TitleTypography variant="h2">
                Your Conscious Shopping Aid
              </TitleTypography>
              <TitleTypography variant="h3">
                Discover products that delight you and align with what you
                believe in. We deliver recommendations from your friends and
                people you trust who share your values and your product needs.
              </TitleTypography>
              <TitleTypography variant="h3">
                Now you <i>can</i> have it all.
              </TitleTypography>
              <div style={styles.form}>
                {(() => {
                  if (joined) {
                    return (
                      <ColorButton
                        disabled
                        color="primary"
                        variant="outlined"
                        size="large"
                      >
                        You're on the Waitlist!
                      </ColorButton>
                    );
                  }
                  if (joinClicked) {
                    return (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <FormControl>
                            <InputLabel htmlFor="my-input">
                              Email address
                            </InputLabel>
                            <Input
                              id="my-input"
                              aria-describedby="my-helper-text"
                              value={email}
                              onChange={handleChangeEmail}
                              error={error}
                            />
                            <FormHelperText id="my-helper-text">
                              We'll never share your email.
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return (
                      <ColorButton
                        color="primary"
                        variant="outlined"
                        size="large"
                        onClick={() => setJoinClicked(true)}
                      >
                        Join the Waitlist
                      </ColorButton>
                    );
                  }
                })()}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={5}>
          <div style={styles.imageWrapper}>
            <img src={stem} alt="hero stem" style={styles.image} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ColorButton = withStyles((theme) => ({
  root: {
    borderColor: "#F2809C",
    color: "#F2809C",
    "&:hover": {
      borderColor: "#F6F3F3",
    },
    letterSpacing: "0.07em",
  },
}))(Button);

const TitleTypography = withStyles((theme) => ({
  h2: {
    padding: "30px 0",
    // Mobile
    fontSize: "42px",
    lineHeight: "50px",
    color: "#505151",
    [theme.breakpoints.up("sm")]: {
      // Desktop
      fontSize: "64px",
      lineHeight: "75px",
    },
  },
  h3: {
    fontFamily: "Georgia,serif",
    fontWeight: "normal",
    padding: "20px 0",
    color: "#505151",
    // Mobile
    fontSize: "20px",
    lineHeight: "28px",
    [theme.breakpoints.up("sm")]: {
      // Desktop
      fontSize: "28px",
      lineHeight: "38px",
    },
  },
}))(Typography);

const styles = {
  root: {
    display: "flex",
    minHeight: "calc(100vh - 70px)" /* Header height is 70px */,
    backgroundColor: "#F6F3F3",
  },
  image: {
    objectFit: "cover",
    width: "100%",
    maxHeight: "calc(100vh - 70px)" /* Header height is 70px */,
  },
  imageWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    margin: "30px 0",
  },
};
