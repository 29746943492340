import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: { main: "#000 " },
    secondary: { main: "#FF3162" },
  },
  typography: {
    fontFamily: [
      "system-ui",
      "-apple-system",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
    ].join(","),
    h1: {
      // Logo font
      fontSize: "24pt",
      fontWeight: "lighter",
      fontFamily: "Georgia,serif",
    },
    h2: {
      fontWeight: "600",
      fontSize: "24pt",
    },
    h3: {
      fontWeight: "600",
      fontSize: "16pt",
    },
    h4: {
      fontSize: "12pt",
    },
    body1: {
      fontSize: "12pt",
    },
    body2: {
      fontSize: "12pt",
      fontWeight: 700,
      lineHeight: "24px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: "40px",
        borderRadius: 1,
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 700,
      },
    },
  },
});
