import React from "react";

export default function Value({ id }) {
  const name = id;
  return <div style={styles.value}>{name}</div>;
}

const styles = {
  value: {
    fontSize: "11px",
    lineHeight: "11px",
    fontWeight: 400,
    height: "20px",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "2px 7px",
    margin: "2px",
  },
};
