import React, { useRef, useLayoutEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CategoryRow from "../sharedComponents/category/CategoryRow";
import { IS_DEV } from "../config";

// See https://material-ui.com/components/app-bar/#app-bar
export default function AppHeaderBar({ onChangeHeight }) {
  const ref = useRef();
  useLayoutEffect(() => {
    onChangeHeight(ref.current.getBoundingClientRect().height);
  });

  function handleClickSuggestion() {
    window.heap.track("suggest_brand_google_form");
  }

  return (
    <AppBar ref={ref} color="inherit" position="fixed" style={styles.header}>
      <div>
        <Toolbar disableGutters style={styles.toolbar}>
          <Grid container direction="column">
            <Grid
              container
              alignItems="center"
              style={{ height: "70px", maxHeight: "70px" }}
            >
              <Grid item xs={3}>
                <LogoTypography variant="h1">
                  <RouterLink to="/">Eva</RouterLink>
                </LogoTypography>
              </Grid>
              <Grid item xs={9} sm={6}>
                {IS_DEV && (
                  <div style={styles.search}>
                    <div style={styles.searchIconWrapper}>
                      <SearchIcon style={styles.searchIcon} />
                    </div>
                    <InputBase
                      placeholder="Search for anything"
                      style={styles.input}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={3}>
                <Box display={{ xs: "none", sm: "block" }}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      color="secondary"
                      target="_blank"
                      href="https://docs.google.com/forms/d/1BjrLjYCyLo2-Tix5ZYDmt7DvdfMPRHy38RRk1fSNXJ8/edit"
                      onClick={handleClickSuggestion}
                    >
                      Suggest a Brand
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {IS_DEV && (
              <Box display={{ xs: "none", sm: "block" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                  style={{ height: "70px", maxHeight: "70px" }}
                >
                  <CategoryRow />
                </Grid>
              </Box>
            )}
          </Grid>
        </Toolbar>
      </div>
    </AppBar>
  );
}

const LogoTypography = withStyles((theme) => ({
  h1: {
    letterSpacing: "0.07em",
    fontSize: "24pt",
    fontWeight: "lighter",
    fontFamily: "Georgia,serif",
    color: "#505151",
    "& > a": {
      textDecoration: "none",
    },
    "& a:visited": {
      color: "#505151",
    },
    "& a:link": {
      color: "#505151",
    },
    "& a:active": {
      color: "#505151",
    },
  },
}))(Typography);

const styles = {
  header: {
    boxShadow: "none",
  },
  toolbar: {
    margin: "0 24px",
    borderBottom: "0.5px solid #C7BEC0",
  },
  input: {
    width: "100%",
    fontSize: "14px",
  },
  searchIcon: {
    color: "#D8D0D3",
    margin: "0 10px",
  },
  searchIconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  search: {
    display: "flex",
    height: "35px",
    border: "1px solid #D8D0D3",
    borderRadius: "4px",
  },
};
