/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      description
      locationDescription
      image {
        src
        description
      }
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          userId
          text
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      values {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          clientId
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrandView = /* GraphQL */ `
  query GetBrandView($id: ID!) {
    getBrandView(id: $id) {
      id
      clientId
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrandViews = /* GraphQL */ `
  query ListBrandViews(
    $filter: ModelBrandViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      tags
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrandCategory = /* GraphQL */ `
  query GetBrandCategory($id: ID!) {
    getBrandCategory(id: $id) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrandCategorys = /* GraphQL */ `
  query ListBrandCategorys(
    $filter: ModelBrandCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        category {
          id
          name
          description
          tags
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      url
      description
      price {
        amount
        currency
      }
      status
      images {
        src
        description
      }
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      categoryId
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      values {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        description
        price {
          amount
          currency
        }
        status
        images {
          src
          description
        }
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        categoryId
        category {
          id
          name
          description
          tags
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrandReview = /* GraphQL */ `
  query GetBrandReview($id: ID!) {
    getBrandReview(id: $id) {
      id
      userId
      text
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrandReviews = /* GraphQL */ `
  query ListBrandReviews(
    $filter: ModelBrandReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        text
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getValue = /* GraphQL */ `
  query GetValue($id: ID!) {
    getValue(id: $id) {
      id
      name
      description
      products {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listValues = /* GraphQL */ `
  query ListValues(
    $filter: ModelValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductValue = /* GraphQL */ `
  query GetProductValue($id: ID!) {
    getProductValue(id: $id) {
      id
      product {
        id
        name
        url
        description
        price {
          amount
          currency
        }
        status
        images {
          src
          description
        }
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        categoryId
        category {
          id
          name
          description
          tags
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProductValues = /* GraphQL */ `
  query ListProductValues(
    $filter: ModelProductValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductValues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        product {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        value {
          id
          name
          description
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrandValue = /* GraphQL */ `
  query GetBrandValue($id: ID!) {
    getBrandValue(id: $id) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrandValues = /* GraphQL */ `
  query ListBrandValues(
    $filter: ModelBrandValueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandValues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        value {
          id
          name
          description
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWaitlistItem = /* GraphQL */ `
  query GetWaitlistItem($email: AWSEmail!) {
    getWaitlistItem(email: $email) {
      email
      createdAt
      updatedAt
    }
  }
`;
export const listWaitlistItems = /* GraphQL */ `
  query ListWaitlistItems(
    $email: AWSEmail
    $filter: ModelWaitlistItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWaitlistItems(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
