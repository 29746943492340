import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { getCategory } from "../../graphql/queries";
import ProductCard from "../product/ProductCard";
import Grid from "@material-ui/core/Grid";

export default function CategoryPage() {
  const { id } = useParams();
  const [category, setCategory] = useState(null);

  useEffect(() => {
    async function fetchCategory() {
      try {
        const { data } = await API.graphql({
          query: getCategory,
          variables: { id },
        });
        setCategory(data.getCategory);
      } catch (error) {
        const { data } = error;
        setCategory(data.getCategory);
      }
    }
    fetchCategory();
  }, [id]);

  if (!category) {
    return null;
  }

  const { products: productsConnection } = category;
  const products = productsConnection.items;

  return (
    <div style={styles.root}>
      {!!products && (
        <Grid container>
          {products.map((product) => (
            <Grid item key={product.id}>
              <ProductCard product={product} showPDPButton />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

const styles = {
  root: {},
};
