/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      description
      locationDescription
      image {
        src
        description
      }
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          userId
          text
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      values {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          clientId
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      description
      locationDescription
      image {
        src
        description
      }
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          userId
          text
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      values {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          clientId
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      description
      locationDescription
      image {
        src
        description
      }
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          userId
          text
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      values {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          clientId
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBrandView = /* GraphQL */ `
  mutation CreateBrandView(
    $input: CreateBrandViewInput!
    $condition: ModelBrandViewConditionInput
  ) {
    createBrandView(input: $input, condition: $condition) {
      id
      clientId
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrandView = /* GraphQL */ `
  mutation UpdateBrandView(
    $input: UpdateBrandViewInput!
    $condition: ModelBrandViewConditionInput
  ) {
    updateBrandView(input: $input, condition: $condition) {
      id
      clientId
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrandView = /* GraphQL */ `
  mutation DeleteBrandView(
    $input: DeleteBrandViewInput!
    $condition: ModelBrandViewConditionInput
  ) {
    deleteBrandView(input: $input, condition: $condition) {
      id
      clientId
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      products {
        items {
          id
          name
          url
          description
          price {
            amount
            currency
          }
          status
          images {
            src
            description
          }
          brandId
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          categoryId
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          values {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          category {
            id
            name
            description
            tags
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBrandCategory = /* GraphQL */ `
  mutation CreateBrandCategory(
    $input: CreateBrandCategoryInput!
    $condition: ModelBrandCategoryConditionInput
  ) {
    createBrandCategory(input: $input, condition: $condition) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrandCategory = /* GraphQL */ `
  mutation UpdateBrandCategory(
    $input: UpdateBrandCategoryInput!
    $condition: ModelBrandCategoryConditionInput
  ) {
    updateBrandCategory(input: $input, condition: $condition) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrandCategory = /* GraphQL */ `
  mutation DeleteBrandCategory(
    $input: DeleteBrandCategoryInput!
    $condition: ModelBrandCategoryConditionInput
  ) {
    deleteBrandCategory(input: $input, condition: $condition) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      url
      description
      price {
        amount
        currency
      }
      status
      images {
        src
        description
      }
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      categoryId
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      values {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      url
      description
      price {
        amount
        currency
      }
      status
      images {
        src
        description
      }
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      categoryId
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      values {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      url
      description
      price {
        amount
        currency
      }
      status
      images {
        src
        description
      }
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      categoryId
      category {
        id
        name
        description
        tags
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      values {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBrandReview = /* GraphQL */ `
  mutation CreateBrandReview(
    $input: CreateBrandReviewInput!
    $condition: ModelBrandReviewConditionInput
  ) {
    createBrandReview(input: $input, condition: $condition) {
      id
      userId
      text
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrandReview = /* GraphQL */ `
  mutation UpdateBrandReview(
    $input: UpdateBrandReviewInput!
    $condition: ModelBrandReviewConditionInput
  ) {
    updateBrandReview(input: $input, condition: $condition) {
      id
      userId
      text
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrandReview = /* GraphQL */ `
  mutation DeleteBrandReview(
    $input: DeleteBrandReviewInput!
    $condition: ModelBrandReviewConditionInput
  ) {
    deleteBrandReview(input: $input, condition: $condition) {
      id
      userId
      text
      brandId
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createValue = /* GraphQL */ `
  mutation CreateValue(
    $input: CreateValueInput!
    $condition: ModelValueConditionInput
  ) {
    createValue(input: $input, condition: $condition) {
      id
      name
      description
      products {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateValue = /* GraphQL */ `
  mutation UpdateValue(
    $input: UpdateValueInput!
    $condition: ModelValueConditionInput
  ) {
    updateValue(input: $input, condition: $condition) {
      id
      name
      description
      products {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteValue = /* GraphQL */ `
  mutation DeleteValue(
    $input: DeleteValueInput!
    $condition: ModelValueConditionInput
  ) {
    deleteValue(input: $input, condition: $condition) {
      id
      name
      description
      products {
        items {
          id
          product {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          brand {
            id
            name
            description
            locationDescription
            createdAt
            updatedAt
          }
          value {
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProductValue = /* GraphQL */ `
  mutation CreateProductValue(
    $input: CreateProductValueInput!
    $condition: ModelProductValueConditionInput
  ) {
    createProductValue(input: $input, condition: $condition) {
      id
      product {
        id
        name
        url
        description
        price {
          amount
          currency
        }
        status
        images {
          src
          description
        }
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        categoryId
        category {
          id
          name
          description
          tags
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProductValue = /* GraphQL */ `
  mutation UpdateProductValue(
    $input: UpdateProductValueInput!
    $condition: ModelProductValueConditionInput
  ) {
    updateProductValue(input: $input, condition: $condition) {
      id
      product {
        id
        name
        url
        description
        price {
          amount
          currency
        }
        status
        images {
          src
          description
        }
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        categoryId
        category {
          id
          name
          description
          tags
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductValue = /* GraphQL */ `
  mutation DeleteProductValue(
    $input: DeleteProductValueInput!
    $condition: ModelProductValueConditionInput
  ) {
    deleteProductValue(input: $input, condition: $condition) {
      id
      product {
        id
        name
        url
        description
        price {
          amount
          currency
        }
        status
        images {
          src
          description
        }
        brandId
        brand {
          id
          name
          description
          locationDescription
          image {
            src
            description
          }
          products {
            nextToken
          }
          reviews {
            nextToken
          }
          values {
            nextToken
          }
          categories {
            nextToken
          }
          views {
            nextToken
          }
          createdAt
          updatedAt
        }
        categoryId
        category {
          id
          name
          description
          tags
          products {
            nextToken
          }
          brands {
            nextToken
          }
          createdAt
          updatedAt
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBrandValue = /* GraphQL */ `
  mutation CreateBrandValue(
    $input: CreateBrandValueInput!
    $condition: ModelBrandValueConditionInput
  ) {
    createBrandValue(input: $input, condition: $condition) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrandValue = /* GraphQL */ `
  mutation UpdateBrandValue(
    $input: UpdateBrandValueInput!
    $condition: ModelBrandValueConditionInput
  ) {
    updateBrandValue(input: $input, condition: $condition) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrandValue = /* GraphQL */ `
  mutation DeleteBrandValue(
    $input: DeleteBrandValueInput!
    $condition: ModelBrandValueConditionInput
  ) {
    deleteBrandValue(input: $input, condition: $condition) {
      id
      brand {
        id
        name
        description
        locationDescription
        image {
          src
          description
        }
        products {
          items {
            id
            name
            url
            description
            status
            brandId
            categoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            userId
            text
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        values {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        categories {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        views {
          items {
            id
            clientId
            brandId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      value {
        id
        name
        description
        products {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        brands {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWaitlistItem = /* GraphQL */ `
  mutation CreateWaitlistItem(
    $input: CreateWaitlistItemInput!
    $condition: ModelWaitlistItemConditionInput
  ) {
    createWaitlistItem(input: $input, condition: $condition) {
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateWaitlistItem = /* GraphQL */ `
  mutation UpdateWaitlistItem(
    $input: UpdateWaitlistItemInput!
    $condition: ModelWaitlistItemConditionInput
  ) {
    updateWaitlistItem(input: $input, condition: $condition) {
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaitlistItem = /* GraphQL */ `
  mutation DeleteWaitlistItem(
    $input: DeleteWaitlistItemInput!
    $condition: ModelWaitlistItemConditionInput
  ) {
    deleteWaitlistItem(input: $input, condition: $condition) {
      email
      createdAt
      updatedAt
    }
  }
`;
