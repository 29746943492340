import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { getBrand } from "../../graphql/queries";
import { createBrandReview, createBrandView } from "../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import useClientId from "../../hooks/useClientId";

const TABS = ["summary", "reviews"];

export default function BrandPage() {
  const history = useHistory();
  const { id, tabName } = useParams();
  const [brand, setBrand] = useState(null);
  let tab = tabName
    ? TABS.findIndex((name) => name === tabName.toLowerCase())
    : 0;
  tab = tab < 0 ? 0 : tab;
  const clientId = useClientId();

  useEffect(() => {
    async function viewBrand() {
      try {
        await API.graphql({
          query: createBrandView,
          variables: {
            input: { id: `${clientId}${id}`, brandId: id, clientId },
          },
        });
      } catch (error) {
        // do nothing
      }
    }
    if (clientId) {
      viewBrand();
    }
  }, [id, clientId]);

  useEffect(() => {
    async function fetchBrand() {
      try {
        const { data } = await API.graphql({
          query: getBrand,
          variables: { id },
        });
        setBrand(data.getBrand);
      } catch ({ data }) {
        setBrand(data.getBrand);
      }
    }
    fetchBrand();
  }, [id]);

  if (!brand) {
    return null;
  }

  const {
    name,
    description,
    locationDescription,
    image,
    // values,
    // products,
    reviews,
  } = brand;

  return (
    <Grid container direction="column" style={styles.root}>
      <Grid item>
        <Grid container style={styles.header}>
          <Grid item style={styles.imageWrapper}>
            <img src={image.src} alt={image.description} style={styles.image} />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Typography variant="h2">{name}</Typography>
              <Tabs
                value={tab}
                onChange={(event, value) =>
                  value > 0
                    ? history.push(`/brand/${id}/${TABS[value]}`)
                    : history.push(`/brand/${id}`)
                }
              >
                {TABS.map((tab) => (
                  <Tab key={tab} label={tab} />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TabPanel value={tab} index={0}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h3">About</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{description}</Typography>
              <Typography variant="body1">{locationDescription}</Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ReviewForm id={id} initialReviews={reviews ? reviews.items : null} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

function ReviewForm({ id, initialReviews }) {
  const [text, setText] = useState<string>("");
  const [reviews, setReviews] = useState(initialReviews);

  async function handleClick() {
    try {
      const { data } = await API.graphql({
        query: createBrandReview,
        variables: {
          input: {
            userId: "1",
            brandId: id,
            text,
          },
        },
      });
      setText("");
      setReviews([data.createBrandReview, ...reviews]);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={styles.reviewForm}
      >
        <Grid item xs={7}>
          <TextareaAutosize
            rowsMin={3}
            placeholder="Write a review..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={styles.reviewTextarea}
          />
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" onClick={handleClick} disabled={!text}>
            Go
          </Button>
        </Grid>
      </Grid>
      {reviews && reviews.length > 0 && (
        <Grid container direction="column" alignItems="stretch">
          {reviews.map((review) => (
            <Grid key={review.id} item>
              <Grid container justify="center">
                <Grid item xs={10}>
                  <Review {...review} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

function TabPanel({ children, value, index }) {
  if (value !== index) {
    return null;
  }

  return (
    <Grid container style={styles.tabPanel}>
      {children}
    </Grid>
  );
}

function Review({ id, text, createdAt, userId }) {
  return (
    <Card style={styles.review}>
      <CardContent>
        <Typography variant="body1">{text}</Typography>
        <Typography>{new Date(createdAt).toString()}</Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

const styles = {
  root: {
    backgroundColor: "#F6F3F3",
    minHeight: "100vh",
  },
  header: {
    padding: "20px",
    backgroundColor: "white",
  },
  imageWrapper: {
    // height: "15vw",
    width: "15vw",
    marginRight: "20px",
  },
  image: {
    objectFit: "contain",
    height: "auto",
    maxHeight: "15vw", // Image wrapper height
    maxWidth: "100%",
  },
  tabPanel: {
    padding: "20px",
  },
  reviewForm: {
    marginBottom: "20px",
    borderRadius: "5px",
  },
  reviewTextarea: {
    padding: "10px",
    width: "calc(100% - 20px - 20px)",
    maxWidth: "calc(100% - 20px - 20px)",
    fontFamily: "Roboto,Arial",
    borderRadius: "10px",
    border: "none",
    resize: "none",
  },
  review: {
    margin: "10px",
  },
};
