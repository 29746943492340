/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ee5c7332-ff5d-4288-9a1f-73dacfeb91b5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Z63PAxTfb",
    "aws_user_pools_web_client_id": "1cm9gncjrqc9n7g16rukj6as9c",
    "oauth": {
        "domain": "web2772770c-2772770c-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:3000/",
        "redirectSignOut": "https://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://jmlbf7upffdwplueurmd23ocgi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-iwn4cqqbqzadnps4364q57x27q"
};


export default awsmobile;
