import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { API } from "aws-amplify";
import { listBrands } from "../../graphql/queries";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BrandValues from "./BrandValues";

const PAGE_SIZE = 8;
const TOKENS = { 0: null };

export default function BrandsPage() {
  const [brands, setBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    async function fetchBrands(page: number) {
      const token: string | null = TOKENS[page] ?? null;
      try {
        const { data } = await API.graphql({
          query: listBrands,
          variables: { limit: PAGE_SIZE, nextToken: token },
        });
        TOKENS[page + 1] = data.listBrands.nextToken;
        setBrands(data.listBrands.items);
      } catch (error) {
        console.error(error);
      }
    }

    fetchBrands(currentPage);
  }, [currentPage]);

  return (
    <Grid container direction="column" style={styles.root}>
      <Grid container spacing={4}>
        {brands.map(
          ({
            id,
            name,
            description,
            locationDescription,
            image,
            values,
            views,
          }) => (
            <Grid item key={id} xs={12} sm={4} md={3}>
              <Card style={styles.card}>
                <CardActionArea
                  component={RouterLink}
                  to={`/brand/${id}`}
                  style={{ height: "100%" }}
                >
                  {image && (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={styles.imageWrapper}
                    >
                      <img
                        src={image.src}
                        alt={image.description}
                        style={styles.image}
                      />
                    </Grid>
                  )}
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={styles.cardContent}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={styles.cardHeader}
                    >
                      <Grid item>
                        <Typography variant="body2">{name}</Typography>
                      </Grid>
                      {views && views.items.length > 0 && (
                        <Grid item>
                          <Typography variant="body1" color="secondary">
                            {views.items.length === 1
                              ? "1 person viewed"
                              : `${views.items.length} people viewed`}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    {values && values.items.length > 0 && (
                      <Grid item>
                        <BrandValues ids={values.items.map(({ id }) => id)} />
                      </Grid>
                    )}
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          )
        )}
      </Grid>
      <Grid container justify="center" style={styles.buttonGroup}>
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage <= 0}
        >
          {"< Previous"}
        </Button>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={TOKENS[currentPage + 1] == null}
        >
          {"Next >"}
        </Button>
      </Grid>
    </Grid>
  );
}

const styles = {
  root: {
    padding: "30px",
  },
  buttonGroup: {
    margin: "40px 0",
  },
  card: {
    height: "100%",
    border: "1px solid #C7BEC0",
    borderRadius: 0,
    boxShadow: "none",
  },
  cardHeader: {
    height: "60px",
  },
  cardContent: {
    padding: "10px",
  },
  imageWrapper: {
    minHeight: "10vw",
    padding: "15px",
  },
  image: {
    objectFit: "contain",
    height: "auto",
    maxHeight: "calc(10vh - 15px)", // Image wrapper height - padding
    maxWidth: "75%",
  },
};
