import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { listProductValues } from "../../graphql/queries";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Value from "../value/Value";

export default function ProductCard({ product, showPDPButton = false }) {
  const {
    id,
    name,
    url,
    description = null,
    price = null,
    images = [],
    values = null,
    numReviews = 0,
    numSwaps = 0,
    topReview = null,
  } = product;
  return (
    <div style={styles.root}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={5}>
              {!!images && images.length > 0 && (
                <img
                  src={images[0].src}
                  alt={images[0].description}
                  style={styles.image}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h2">{name}</Typography>
                </Grid>
                {description && (
                  <Grid item>
                    <Typography variant="body1">{description}</Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography color="secondary" variant="body1">
                    {(() => {
                      if (numSwaps === 1) {
                        return "1 person swapped";
                      } else if (numSwaps > 1) {
                        return `${numSwaps} people swapped`;
                      } else {
                        return null;
                      }
                    })()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {(() => {
                      if (numReviews === 1) {
                        return "1 review";
                      } else if (numReviews > 1) {
                        return `${numReviews} reviews`;
                      } else {
                        return null;
                      }
                    })()}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    {price && (
                      <Grid item>
                        <Typography variant="body1">
                          Price: ${(price.amount / 100.0).toFixed(2)}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        target="_blank"
                        href={url}
                      >
                        Visit Product Page
                      </Button>
                    </Grid>
                    {showPDPButton && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={`/product/${id}`}
                        >
                          See More Details
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {!!values && !!values.items && (
            <ProductValues ids={values.items.map(({ id }) => id)} />
          )}
          {topReview && (
            <Grid container>
              <Typography variant="h4">Top Review for You</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function ProductValues({ ids }) {
  const [productValues, setProductValues] = useState([]);
  useEffect(() => {
    async function fetchProductValues() {
      try {
        const { data } = await API.graphql({ query: listProductValues });
        setProductValues(data.listProductValues.items);
      } catch (error) {
        console.error(error);
      }
    }
    fetchProductValues();
  }, [ids]);

  if (productValues.length === 0) {
    return null;
  }

  const values = [];
  ids.forEach((id) => {
    const value = productValues.find((value) => value.id === id);
    if (value) {
      values.push(value);
    }
  });

  return (
    <Grid container>
      {values.map(({ value }) => (
        <Grid key={value.id} item>
          <Value id={value.name} />
        </Grid>
      ))}
    </Grid>
  );
}

const styles = {
  root: {
    padding: "20px",
  },
  image: {
    width: "100%",
  },
};
