import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useLocalStorage from "./useLocalStorage";

export default function useClientId() {
  const [localStorageClientId, setLocalStorageClientId] = useLocalStorage(
    "clientId",
    null
  );
  const [clientId, setClientId] = useState<string | null>(localStorageClientId);

  useEffect(() => {
    if (!clientId) {
      const id = uuidv4();
      setLocalStorageClientId(id);
      setClientId(id);
    }
  }, [clientId, setLocalStorageClientId]);

  return clientId;
}
